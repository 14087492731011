<template>
  <div class="kb-header-bar q-pa-md text-right">
    <q-btn color="black" icon="login" label="Login" v-if="!isLoggedIn" @click="startLogin('')" />
    <q-btn color="black" :label="currentUser?.name" v-if="!!isLoggedIn">
      <q-menu>
        <div class="row no-wrap q-pa-md q-gutter-md">
          <!--
          <div class="column">
            XXX
          </div>
          <q-separator dark vertical inset></q-separator>
          -->
          <div class="column items-center">
            <q-avatar size="72px">
              <img v-if="currentUser?.profile" :src="currentUser?.profile">
            </q-avatar>

            <div class="text-subtitle1 q-mt-md q-mb-xs">{{currentUser?.name}}</div>

            <q-btn
              color="primary"
              label="Logout"
              push
              size="sm"
              v-close-popup
              @click="logout"
            />
          </div>
        </div>
      </q-menu>
    </q-btn>
  </div>
</template>

<script>
import useUserStore from "@/modules/auth/store";
import { computed, defineComponent } from "vue";

export default defineComponent({
  setup() {

    const { currentUser, startLogin, logout } = useUserStore();
    const isLoggedIn = computed(() => currentUser.value !== null);

    return {
      currentUser, 
      isLoggedIn,
      startLogin,
      logout
    }
  },
})
</script>

<style scoped lang="scss">

.kb-header-bar {
  height: 60px;
}

</style>