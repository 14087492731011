<template>
  <q-dialog class="kb-login-dialog" v-model="showDialog" @hide="$emit('closed')">
    <q-card>
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">Login</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section class="text-center">
        {{loginMessage}}
      </q-card-section>

      <q-card-section class="text-center">
        <q-form class="q-pa-md q-gutter-md">
          <q-input
            type="email"
            placeholder="E-Mail address"
            autofocus
            v-model="email"
            autocomplete="username"
            :error="emailError"
          />
          <q-input
            type="password"
            placeholder="Password"
            v-model="password"
            autocomplete="password"
            :error="passwordError"
          />
          <div>
            <q-btn color="primary" size="lg" icon="login" label="Einloggen" class="full-width" @click="loginWithCredentials"></q-btn>
          </div>
          <div>
            <q-btn color="facebook" size="lg" icon="facebook" label="Facebook Login" class="full-width" @click="loginWithFacebook"></q-btn>
          </div>
          <div>
            <q-btn color="apple" size="lg" icon="apple" label="Apple Login" class="full-width" @click="loginWithApple"></q-btn>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import useUserStore from "../store";
import { FacebookLoginResult } from '@/api/users/FacebookAuthResponse';

export default defineComponent({
  props: {
    show: Boolean
  },
  setup (props) {

    const showDialog = ref(false);
    const emailError = ref(false);
    const email = ref('');
    const password = ref('');
    const passwordError = ref(false);
    const userStore = useUserStore();

    async function loginWithCredentials(): Promise<void> {
      emailError.value = !email.value;
      passwordError.value = !password.value;
      if (emailError.value || passwordError.value) {
        return;
      }

      await userStore.loginWithCredentials(email.value, password.value);
    }

    function loginWithFacebook() {
      window.FB.init({
        appId: '630797020265462',
        xfbml: true,
        version: 'v12.0'
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.FB.login((response: FacebookLoginResult) => {
        if (response.status === 'connected' && response.authResponse?.accessToken) {
          userStore.loginWithFacebook(response.authResponse.accessToken);
        }
      });
    }

    async function loginWithApple(): Promise<void> {
      const returnUrl = window.location.href.toString().split('?')[0];
      window.AppleID.auth.init({
        clientId: 'com.kickbase.play',
        scope: '',
        redirectURI: returnUrl,
        responseType: 'code',
        state: Math.random().toString(),
        nonce: Math.random().toString(),
        usePopup: true
      });
      try {
        const response = await window.AppleID.auth.signIn();
        const idToken = response.authorization.id_token;
        await userStore.loginWithApple(idToken);
      } catch(e) {
        console.log('ERR', e);
      }
    }

    watch(() => props.show, (newValue) => {
      showDialog.value = newValue;
    })

    return {
      showDialog,
      email,
      emailError,
      password,
      passwordError,
      loginMessage: computed(() => userStore.loginMessage.value),
      loginWithCredentials,
      loginWithApple,
      loginWithFacebook
    }
  },
})

</script>

<style scoped lang="scss">

@media screen and (min-width: 991px) {
  .kb-login-dialog {
    .q-card {
      min-width: 500px;
    }
  }  
}

</style>