import { AxiosResponse } from 'axios';
import { api } from '@/api/ApiBase';
import { GetPurchaseResultResponse } from './GetPurchaseResultResponse';
import { CheckoutResponse } from './CheckoutResponse';
import { RedeemCouponResponse } from './RedeemCouponResponse';

/**
 * Shop api.
 */
export class ShopApi {
  /**
   * Gets the purchase result for a payment session.
   * @param sessionId Purchase session id.
   * @returns Purchase result.
   */
  public static getPurchaseResult(sessionId: string): Promise<AxiosResponse<GetPurchaseResultResponse>> {
    return api.client.get('/store/purchaseresult', {
      params: {
        sessionId
      }
    });
  }

  /**
   * Initiates a checkout.
   * @param service Id of payment service.
   * @param productId Id of product.
   * @returns Checkout response.
   */
  public static checkout(service: string, productId: string): Promise<AxiosResponse<CheckoutResponse>> {
    const data = {
      productId: productId,
      source: 'web_shop',
      type: service,
    };
    return api.client.post('/store/checkout', data);
  }

  /**
   * Completes the purchase.
   */
  public static complete(sessionId: string): Promise<AxiosResponse<GetPurchaseResultResponse>> {
    return api.client.post('/store/complete', null, {
      params: {
        sessionId
      }
    });
  }

  /**
   * Redeems a coupon code.
   */
  public static redeemCouponCode(code: string): Promise<AxiosResponse<RedeemCouponResponse>> {
    return api.client.post('/store/redeem', { code });
  }
}
