// import DealsView from '../views/DealsView.vue';
import RedeemCouponCodeView from '../views/RedeemCouponCodeView.vue';

const routes = [
  /*
  {
    path: '/shop',
    alias: ['/xmas'],
    name: 'Shop',
    component: DealsView,
    meta: {
      requiresAuth: false,
      title: 'Shop',
    },
  },
  */
  {
    path: '/gutschein',
    alias: ['/freibier'],
    name: 'redeemCouponCode',
    component: RedeemCouponCodeView,
    meta: {
      requiresAuth: false,
      title: 'Freibier!',
      showBackgroundImage: true
    },
  },
];

export default routes;
