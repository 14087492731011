<template>
  <div class="kb-home">
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from "vue";

export default defineComponent({
  setup() {

    onBeforeMount(() => {
      window.location.href = 'https://www.kickbase.com';
    });

    return {};
  }
})
</script>

<style scoped lang="scss">
</style>
