import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/modules/home/views/HomeView.vue';

import connectRoutes from '@/modules/connect/routes';
import shopRoutes from '@/modules/shop/routes';

const routes = [
  ...connectRoutes,
  ...shopRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: 'Home',
    component: HomeView,
    meta: {
      requiresAuth: false,
      title: 'Home',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router;
