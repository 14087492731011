import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-77a7a6b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kb-connect" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-6 offset-md-3 q-gutter-md" }
const _hoisted_4 = { class: "kb-test-headline kb-headline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, "Mit " + _toDisplayString(_ctx.appName) + " verbinden", 1),
        _createElementVNode("div", null, [
          _createVNode(_component_q_btn, {
            color: "primary",
            size: "lg",
            label: "Verbinden",
            onClick: _ctx.doConnect
          }, null, 8, ["onClick"])
        ])
      ])
    ])
  ]))
}