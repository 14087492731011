import { Notify } from 'quasar';

/**
 * Show toast message
 */
export function showToast(message: string, color: string): void {
  Notify.create({
    color,
    message,
    position: 'top-right',
    timeout: 1500,
    progress: true,
  });
}

/**
 * Show success notification
 */
export function showSuccess(message?: string): void {
  showToast(message || 'Success', 'positive');
}

/**
 * Error notification
 */
export function showError(message?: string): void {
  showToast(message || 'Error', 'negative');
}
