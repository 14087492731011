<template>
  <header-bar />
  <login-dialog :show="isLoggingIn" @closed="cancelLogin" />
  <router-view></router-view>
</template>

<script lang="ts">
import HeaderBar from "./modules/common/components/HeaderBar/HeaderBar.vue";
import LoginDialog from "./modules/auth/components/LoginDialog.vue";
import { defineComponent, onMounted } from "vue";
import useUserStore from "./modules/auth/store";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {

    const { isLoggingIn, cancelLogin, restoreToken, loadCurrentUser } = useUserStore();
    const router = useRouter();

    router.beforeEach((route) => {
      document.title = 'KICKBASE - ' + (route.meta.title as string || 'Home').toUpperCase();
      if (route.meta?.showBackgroundImage === true) {
        document.body.classList.add('kb-background-image');
      } else {
        document.body.classList.remove('kb-background-image');
      }
    });

    onMounted(async () => {
      restoreToken();
      await loadCurrentUser();
    });

    return {
      isLoggingIn,
      cancelLogin
    }
  },
  components: {
    HeaderBar,
    LoginDialog
  }
})

/*
@Options({
  components: {
    HeaderBar,
    LoginDialog
  },
})
export default class App extends Vue {
  async mounted(): Promise<void> {
    try {
      dispatchRestoreToken(this.$store);
      if (this.isAuthenticated) {
        await dispatchGetMe(this.$store);
      }
    } catch (err) {
      dispatchLogout(this.$store);
    }
  }

  get isAuthenticated(): boolean {
    return isLoggedIn(this.$store);
  }

  get currentUser(): UserProfileData|undefined {
    return getCurrentUser(this.$store);
  }

  logout(): void {
    dispatchLogout(this.$store);
  }

  @Watch('$route', { immediate: true, deep: true })
  private onRouteChanged(to: RouteRecord) {
    document.title = to.meta.title ? `Kickbase - ${to.meta.title}` : 'Kickbase'
  }
}
*/
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
