import { AxiosResponse } from 'axios';
import { api } from '@/api/ApiBase';
import { GetAppInfoResponse } from './GetAppInfoResponse';
import { InitiateSSORequest } from './InitiateSSORequest';
import { InitiateSSOResponse } from './InitiateSSOResponse';

/**
 * SSO api.
 */
export class SSOApi {
  /**
   * Gets information about an app.
   * @param appId App id.
   * @returns App info.
   */
  public static getAppInfo(appId: string): Promise<AxiosResponse<GetAppInfoResponse>> {
    return api.client.get(`/sso/${appId}/info`);
  }
  /**
   * Initiate sso.
   * @param appId Id of app to connect.
   * @param params Params.
   */
  public static initiate(appId: string, params: InitiateSSORequest): Promise<AxiosResponse<InitiateSSOResponse>> {
    return api.client.post(`/sso/${appId}`, {}, { params });
  }
}
