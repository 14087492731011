import { api } from '@/api/ApiBase';
import { UsersApi } from '@/api/users';
import { UserProfileData } from '@/api/users/UserProfileData';
import { ref, computed, ComputedRef } from "vue";

interface UserState2 {
  isLoggingIn: ComputedRef<boolean>;
  isLoginPending: ComputedRef<boolean>;
  isLoggedIn: ComputedRef<boolean>;
  token: ComputedRef<string|null>;
  currentUser: ComputedRef<UserProfileData|null>;
  loginMessage: ComputedRef<string|null>;
  startLogin: (message: string|null) => void;
  cancelLogin: () => void;
  loginWithCredentials: (username: string, password: string) => Promise<void>;
  loginWithApple: (idToken: string) => Promise<void>;
  loginWithFacebook: (fbToken: string) => Promise<void>;
  logout: () => void;
  restoreToken: () => void;
  loadCurrentUser: () => Promise<void>;
}

const ACCESS_TOKEN_STORAGE_ITEM_KEY = 'kb.t';

const isLoggingIn = ref(false);
const isLoginPending = ref(false);
const currentUser = ref<UserProfileData|null>(null);
const token = ref<string|null>(null);
const loginMessage = ref<string|null>(null);

/**
 * Sets the access token.
 */
function setToken(newToken: string|null) {
  token.value = newToken;
  api.setToken(newToken ? newToken : undefined);
  if (!newToken) {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_ITEM_KEY);
  } else {
    localStorage.setItem(ACCESS_TOKEN_STORAGE_ITEM_KEY, newToken);
  }
}

export default function useUserStore(): UserState2 {
  return {
    isLoggingIn: computed(() => isLoggingIn.value),
    isLoginPending: computed(() => isLoginPending.value),
    isLoggedIn: computed(() => !!currentUser.value),
    token: computed(() => token.value),
    currentUser: computed(() => currentUser.value),
    loginMessage: computed(() => loginMessage.value),
    /**
     * Starts the login process.
     */
    startLogin: (message: string|null) => {
      loginMessage.value = message;
      isLoggingIn.value = true;
    },
    /**
     * Cancels the login process.
     */
    cancelLogin: () => {
      isLoggingIn.value = false;
    },
    /**
     * Login with credentials.
     */
    loginWithCredentials: async (username: string, password: string) => {
      try {
        const response = await UsersApi.login({ email: username, password });
        currentUser.value = response.data.user;
        setToken(response.data.token);
        isLoggingIn.value = false;
      } catch(e) {
        console.log('login error');
      }
    },
    /**
     * Login with apple.
     */
     loginWithApple: async (idToken: string) => {
      try {
        const response = await UsersApi.login({ appleToken: btoa(idToken), loginOnly: true });
        currentUser.value = response.data.user;
        setToken(response.data.token);
        isLoggingIn.value = false;
      } catch(e) {
        console.log('login error');
      }
    },
    /**
     * Login with facebook.
     */
     loginWithFacebook: async (fbToken: string) => {
      try {
        const response = await UsersApi.login({ fbToken, loginOnly: true });
        currentUser.value = response.data.user;
        setToken(response.data.token);
        isLoggingIn.value = false;
      } catch(e) {
        console.log('login error');
      }
    },
    /**
     * Logout.
     */
    logout: () => {
      currentUser.value = null;
      setToken(null);
    },
    /**
     * Restores the auth token.
     */
    restoreToken: () => {
      setToken(localStorage.getItem(ACCESS_TOKEN_STORAGE_ITEM_KEY));
    },
    /**
     * Loads the current user info.
     */
    loadCurrentUser: async () => {
      try {        
        if (token.value) {
          const response = await UsersApi.me();
          currentUser.value = response.data.user;
          isLoggingIn.value = false;
        }
      } catch(e) {
        setToken(null);
      }
    }
  };
}
