import { createApp } from "vue";
import App from "./App.vue";
import router from './router'
import { Quasar } from 'quasar'
import { quasarConfig } from "./quasar";
import './assets/scss/main.scss';

const app = createApp(App);
app.use(router);
app.use(Quasar, quasarConfig);
app.mount('#app');
