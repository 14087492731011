<template>
  <div class="kb-connect">
    <div class="row">
      <div class="col-md-6 offset-md-3 q-gutter-md">
        <h4 class="kb-test-headline kb-headline">Mit {{appName}} verbinden</h4>
        <div>
          <q-btn color="primary" size="lg" label="Verbinden" @click="doConnect"></q-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { SSOApi } from "@/api/sso";
import useUserStore from "@/modules/auth/store";

export default defineComponent({
  setup () {

    const route = useRoute();
    const router = useRouter();
    const appName = ref('');
    const userStore = useUserStore();


    async function doConnect() {
      if (!userStore.isLoggedIn.value) {
        userStore.startLogin('');
      } else {
        const appId = route.params.appId as string;
        const callback = route.query.callback as string;
        try {
          const response = await SSOApi.initiate(appId, { reference: 'web', callback });
          if (response.data.url) {
            window.location.href = `${response.data.url}${response.data.url && response.data.url.indexOf('?') >= 0 ? '&' : '?'}${window.location.search ? window.location.search.substr(1) : ''}`
          }
        } catch(e) {
          console.log('ERR', e);
        }
      }
    }

    /**
     * Watch if we are logged in, and autotrigger the SSO
     */
    watch(() => userStore.isLoggedIn.value, (newValue) => {
      if (newValue) {
        doConnect();
      }
    });

    onMounted(async () => {
      const appId = route.params.appId as string;
      if (!appId) {
        router.push({ path: '/' });
        return;
      }
      const response = await SSOApi.getAppInfo(appId);
      appName.value = response.data.name || '';
      if (!userStore.isLoggedIn.value) {
        userStore.startLogin(`Mit ${appName.value} verbinden.`);
      }
    });

    return {
      appName,
      doConnect
    };
  }
});

</script>

<style scoped lang="scss">
  .kb-connect {
    text-align: center;

    .kb-headline {
      text-align: center;
      text-transform: uppercase;
    }
  }
</style>