<template>
  <div class="kb-redeem-coupon-code">
    <div class="row">
      <div class="col-md-4 offset-md-4">
        <div class="q-gutter-md">
          <div class="title text-h2">Gutschein<br/>einlösen</div>
          <q-input
            placeholder="Code"
            autofocus
            v-model="code"
            :error="codeError"
          />
          <div>
            <q-btn color="primary" size="lg" icon="check" label="Gutschein einlösen" class="full-width" @click="redeemCode" :loading="isRedeeming"></q-btn>
          </div>
        </div>
      </div>
    </div>

    <q-dialog v-model="isCodeValid" @hide="code = ''">
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section class="text-center q-gutter-md">
          <div class="text-h4">Glückwunsch!</div>
          <div>Der Code wurde erfolgreich eingelöst!</div>
        </q-card-section>

        <q-card-section class="text-center">
          <q-btn color="primary" size="lg" label="Ok" v-close-popup></q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="isCodeInvalid">
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section class="text-center q-gutter-md">
          <div class="text-h4">Mist.</div>
          <div>Der Code ist leider ungültig!</div>
        </q-card-section>

        <q-card-section class="text-center">
          <q-btn color="primary" size="lg" label="Ok" v-close-popup></q-btn>
        </q-card-section>
      </q-card>
    </q-dialog>

  </div>
</template>

<script lang="ts">
import useUserStore from "@/modules/auth/store";
import { defineComponent, ref } from "vue";
import { ShopApi } from "@/api/shop";

export default defineComponent({
  name: 'RedeemCouponCodeView',
  setup () {

    const codeError = ref(false);
    const code = ref('');
    const isRedeeming = ref(false);
    const userStore = useUserStore();
    const isCodeInvalid = ref(false);
    const isCodeValid = ref(false);

    async function redeemCode() {
      codeError.value = !code.value;
      if (codeError.value) {
        return;
      }

      if (!userStore.isLoggedIn.value) {
        userStore.startLogin('');
      } else {
        isRedeeming.value = true;
        try {
          const response = await ShopApi.redeemCouponCode(code.value);
          isCodeValid.value = response.data.valid || false;
          isCodeInvalid.value = !(response.data.valid || false);
        } catch(e) {
          isCodeValid.value = false;
          isCodeInvalid.value = true;
        }
        isRedeeming.value = false;
      }
    }

    return {
      code,
      codeError,
      isRedeeming,
      isCodeInvalid,
      isCodeValid,
      redeemCode
    };
  }

});

</script>

<style scoped lang="scss">

.kb-redeem-coupon-code {

  padding-top: 80px;

  .title {
    font-family: 'Roboto Slab';
    text-transform: uppercase;
  }
}

</style>
