import ConnectAppView from '../views/ConnectAppView.vue';

const routes = [
  {
    path: '/connect/:appId',
    name: 'Connect',
    component: ConnectAppView,
    meta: {
      requiresAuth: false,
      title: 'Connect',
      showBackgroundImage: true
    },
  },
];

export default routes;
